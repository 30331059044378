/* Make PhoneInput match Ant Design's Input */
.custom-phone-input input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  border-radius: 6px;
  transition: all 0.2s;
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}

.custom-phone-input input:focus {
  border-color: #1890ff;
  outline: none;
  box-shadow: 0 0 3px rgba(24, 144, 255, 0.5);
}

.custom-phone-input input::placeholder {
  color: rgba(0, 0, 0, 0.25); /* Light gray */
}

.custom-phone-input .PhoneInputCountry {
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.is-us-only .PhoneInputCountry {
  display: none;
}
